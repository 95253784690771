@charset "UTF-8";

/*20211129*/
/*movie_modal*/
#movie_modal {
  position:fixed;
  width:100%;
  height:100%;
  top:0;
  left:0;
  z-index:1000;
  display:none;
}
#movie_modal .modal_body{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  display:none;
}
#movie_modal .modal_bg{
  width:100%;
  height:100%;
  background-color:rgba(255,255,255,0.9);
}
#movie_modal .modal_window{
  position: absolute;
  width:90vw;
  max-width:900px!important;
  left:50%;
  top:50%;
  transform: translate(-50%, -50%);
  text-align:center;
}
#movie_modal .youtube_tag{
  position:relative;
  width: 100%;
  padding-top: 56.25%;
  background: #000;
}
#movie_modal .modal_window iframe,
#top-movie-modal .modal_window iframe,
#movie-invention .modal_window iframe {
  position: absolute;
  width:100% !important;
  height:100% !important;
  top: 0;
  left: 0;
}
/*modal_close*/
.modal_close{
  position:absolute;
  margin:0;padding:0;
  width:40px;
  height:40px;
  top:-55px;
  right:0;
  border-radius:50%;
  background-color:rgba(64,65,66,0.6);
  z-index:2;
  cursor:pointer;
}
.modal_close span:nth-child(1){
  position:absolute;
  margin:0;padding:0;
  top:50%;;
  left:50%;
  width:1px;
  height:22px;
  background:#fff;
  transform:translateY(-50%) rotate(45deg);
}
.modal_close span:nth-child(2){
  position:absolute;
  margin:0;padding:0;
  top:50%;;
  left:50%;
  width:1px;
  height:22px;
  background:#fff;
  transform:translateY(-50%) rotate(-45deg);
}
@media screen and (max-width:768px){
  #movie_modal .modal_window{
    position: absolute;
    width: 90vw;
    min-width:initial;
    left:50%;
    top:50%;
    transform: translate(-50%, -50%);
  }
  #movie_modal .youtube_tag{
    width: 100%;
    padding-top: 56.25%;
    position: relative;
    background: #000;
  }
}

/*mov_paly*/
.mov_paly{
  display:block;
  position:relative;
  opacity:1;
}
.mov_paly::after{
  content:'';
  position:absolute;
  width:50px;
  height:50px;
  top:50%;
  left:50%;
  margin-left:-25px;
  margin-top:-75px;
  background-image:url("../images/landing/icon_mov_play.svg");
  background-repeat:no-repeat;
  background-position:center;
  background-size:cover;
  transition: all .3s;
  z-index:2;
}
.mov_paly:hover{
  opacity:1;
}
.mov_paly:hover::after{
  transform: scale(1.2,1.2);
}
@media screen and (max-width:896px){
  .mov_paly::after{
    width:12vw;
    height:12vw;
    margin-left:-6vw;
    margin-top:-15vw;
  }
}

/*price_plans*/
.price_plans{
  position:relative;
  width:100%;
  background-color:#e3f0ff;
}
.price_plans .innr{
  position:relative;
  margin:0 auto;
  padding:5rem 0;
  width:100%;
  max-width:800px;
  text-align:center;
}
.price_plans_ttl{
  margin-bottom:1.5rem;
  font-size:2.4rem;
  line-height:1.8;
  font-weight:400;
}
.price_section{
  position:relative;
  display:block;
}
.price_table{
  position:relative;
  table-layout:fixed;
  border-collapse:collapse;
  width:100%;
  text-align:center;
  font-feature-settings:"palt";
  color:#404041;
}
.price_table th,.price_table td{
  padding:20px 10px;
  vertical-align:middle;
  border:2px solid #e2e2e2;
}
.price_table .tbl_wd1{
  width:24%;
}
.price_table .tbl_wd2{
  width:38%;
}
.price_table .tbl_wd3{
  width:38%;
}
.price_table .tbl_clr1{
  background-color:#999999;
}
.price_table .tbl_clr2{
  background-color:#2286fb;
}
.price_table .tbl_clr3{
  background-color:#f4f4f4;
}
.price_table .tbl_clr4{
  background-color:#e6f0f9;
}
.price_table .tbl_clr5{
  background-color:#fff;
}
.price_table .noline{
  border:none;
}
.price_table .tbl_txt1{
  font-size:1.3rem;
  line-height:1.5;
}
.price_table .tbl_txt2{
  position:relative;
  margin:5px 0 0 0;
  font-size:2.5rem;
  line-height:1.5;
  font-weight:700;
}
.price_table .tbl_txt2_txt1{
  display:inline-block;
  padding-right:8px;
  font-size:1.8rem;
}
.price_table .tbl_txt2_txt2{
  position:absolute;
  display:inline-block;
  padding-left:8px;
  font-size:1.2rem;
  font-weight:normal;
  bottom:7px;
}
.price_table .tbl_txt3{
  font-size:1.2rem;
  line-height:1.5;
}
.price_table .tbl_txt4{
  margin:0;
  font-size:1.7rem;
  line-height:1.6;
}
.price_table .tbl_txt5{
  position:relative;
  margin:5px 0 0 0;
  font-size:2rem;
  line-height:1.6;
  font-weight:700;
}
.price_table .suptxt {
  position:absolute;
  padding-left:5px;
  font-size:1.2rem;
  font-weight:normal;
  vertical-align:top;
}

.price_table_ttl{
  margin:15px 0 0 0;
  font-size:1.4rem;
  line-height:1.5;
  color:#fff;
}
.price_table .icon_free{
  margin:0 auto;
  height:35px;
  width:auto;
}
.price_table .icon_pro{
  margin:0 auto;
  height:35px;
  width:auto;
}
.price_table .icon_free img,.price_table .icon_pro img{
  height:100%;
  width:auto;
}
.price_table .icon_batsu{
  margin:0 auto;
  width:18px;
}
.price_table .icon_maru{
  margin:0 auto;
  width:20px;
}
.price_section_note{
  margin-top:20px;
  font-size:1.2rem;
  line-height:1.6;
  text-align:left;
}
.price_attention{
  display:block;
  margin-top:30px;
  text-align:center;
  font-size:1.8rem;
  font-weight:700;
  line-height:1.5;
  color:#2286fb;
}
.price_change_btn{
  display:block;
  margin:30px auto 0 auto;
  padding:1rem 0;
  width:20rem;
  text-align:center;
  font-size:1.56rem;
  font-weight:400;
  line-height:1.5;
  color:#fff;
  border-radius:3px;
  background-color:#2286fb;
}
@media screen and (max-width:896px){
  .price_plans .innr{
    padding:8vw 0;
    width:90vw;
  }
  .price_plans_ttl{
    margin-bottom:4vw;
    font-size:1.7rem;
    line-height:1.7;
  }
  .price_table_ttl{
    margin-top:1vw;
    font-size:1.2rem;
  }
  .price_table th,.price_table td{
    padding:2vw 2vw;
  }
  .price_table .tbl_txt1{
    display:block;
    font-size:1rem;
    line-height:1.4;
  }
  .price_table .tbl_txt2{
    margin-top:5px;
    font-size:1.5rem;
  }
  .price_table .tbl_txt2_txt1{
    padding-right:5px;
    font-size:1.2rem;
  }
  .price_table .tbl_txt2_txt2{
    position:relative;
    padding-left:5px;
    font-size:1rem;
    bottom:0;
  }
  .price_table .tbl_txt3{
    font-size:1rem;
  }
  .price_table .tbl_txt4{
    font-size:1.2rem;
  }
  .price_table .tbl_txt5{
    margin-top:5px;
    font-size:1.4rem;
  }
  .price_table .suptxt {
    position:relative;
    padding-left:5px;
    font-size:1rem;
  }
  .price_table .icon_free{
    height:7vw;
  }
  .price_table .icon_pro{
    height:7vw;
  }
  .price_table .icon_batsu{
    width:4vw;
  }
  .price_table .icon_maru{
    width:4.5vw;
  }
}
/*END20211129*/


/*main_conts_kv*/
.main_conts_kv{
  position:relative;
  width:100%;
  height:368px;
  background-image:url("../images/landing/main_kv_bg.png");
  background-repeat:no-repeat;
  background-position:center;
  background-size:cover;
}
.main_conts_kv_list{
  position:relative;
  margin:0 auto;
  display:flex;
  justify-content:space-between;
  align-content:center;
  width:100%;
  max-width:800px;
  height:100%;
}
.main_conts_kv_list .kv_lsit_left{
  position:relative;
}
.main_conts_kv_list .kv_list_right{
  position:relative;
  width:500px;
}
.main_kv_img{
  position:absolute;
  width:500px;
  height:auto;
  right:0;
  bottom:-55px;
}
.main_conts_kv_detail{
  margin-top:5.2rem;
  position:relative;
  text-align:center;
}
.main_kv_ttl1{
  margin-bottom:2.5rem;
  font-size:1.68rem;
  line-height:2.6rem;
  font-weight:400;
}

/*20210526*/
.main_kv_logo{
  margin-bottom:4rem;
  width:280px;
  height:auto;
}
.main_kv_ttl2{
  position: relative;
  display: inline-block;
  margin-bottom:1rem;
  font-size:1.5rem;
  line-height:1.6;
  font-weight:700;
}
.main_kv_ttl2::before,.main_kv_ttl2::after {
  content: '';
  position: absolute;
  top: 50%;
  display: inline-block;
  width: 20px;
  height: 2px;
  background-color:#404142;
}
.main_kv_ttl2::before {
  left:-25px;
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
}
.main_kv_ttl2::after {
  right:-25px;
  -webkit-transform: rotate(-60deg);
  transform: rotate(-60deg);
}
/*20210526_END*/

.main_kv_btn{
  display:block;
  margin:0 auto;
  width:19.6rem;
  font-size:1.56rem;
  line-height:4.5rem;
  color:#fff;
  border-radius:.33rem;
  background-color:#2286fb;
}
@media screen and (max-width:896px){
  .main_conts_kv{
    height:119vw;/*122vw;*/
  }
  .main_conts_kv_list{
    display:block;
    width:90vw;
    height:100%;
  }
  .main_conts_kv_list .kv_lsit_left{
    margin-bottom:7vw;
  }
  .main_conts_kv_list .kv_list_right{
    width:90vw;
  }
  .main_kv_img{
    position:relative;
    margin:0 auto;
    width:80vw;
    height:auto;
    right:auto;
    bottom:auto;
  }
  .main_conts_kv_detail{
    margin-top:0;
    padding-top:6vw;
  }
  .main_kv_ttl1{
    margin-bottom:4vw;
    font-size:1.5rem;
    line-height:2.4rem;
  }
  .main_kv_logo{
    margin:0 auto;
    margin-bottom:4vw;
    width:60vw;
  }
  
  /*20210526*/
  .main_kv_ttl2{
    font-size:1.5rem;
    line-height:2.4rem;
  }
  /*20210526*/
  
  .main_kv_btn{
    width:19.6rem;
    font-size:1.56rem;
    line-height:4.5rem;
    color:#fff;
    border-radius:.33rem;
  }
}

/*comm_tool*/
.comm_tool{
  width:100%;
  background-color:#fff;
}
.comm_tool .innr{
  position:relative;
  margin:0 auto;
  padding:5rem 0;
  width:100%;
  max-width:800px;
  text-align:center;
}
.comm_tool_ttl1{
  margin-bottom:2.5rem;
  font-size:2.1rem;
  line-height:1;
  font-weight:700;
  color:#2286fb;
}

.comm_tool_ttl2{
  margin-top: 1rem;/*20210526*/
  margin-bottom:2.5rem;
  font-size:2.9rem;
  line-height:1.6;
}

.comm_tool_ttl2 span{
  display:block;
  font-size:2.1rem;
  line-height:1;
}
.comm_tool_txt{
  margin-bottom:3.5rem;
  font-size:1.3rem;
  line-height:1.8;
}
.comm_tool_list{
  display:flex;
  justify-content:space-between;
  width:100%;
}
.comm_tool_list li{
  margin-right:5.4rem;
  text-align:center;
}
.comm_tool_list li:last-child{
  margin-right:0;
}
.comm_tool_img{
  margin:0 auto;
  width:23rem;
  height:auto;
}
.comm_tool_img_txt{
  margin-top:1.3rem;
  font-size:1.4rem;
  line-height:1.2;
}
@media screen and (max-width:896px){
  .comm_tool .innr{
    margin:0 auto;
    padding:16vw 0 8vw 0;
    width:90vw;
  }
  .comm_tool_ttl1{
    margin-bottom:4vw;
    font-size:1.7rem;
    line-height:1.4;
  }
  .comm_tool_ttl2{
    margin-top: 0;/*20210526*/
    margin-bottom:6vw;
    font-size:1.8rem;
    line-height:1.8;
  }
  .comm_tool_ttl2 span{
    font-size:1.6rem;
    line-height:1.6;
  }
  .comm_tool_txt{
    margin-bottom:3.5rem;
    font-size:1.3rem;
    line-height:1.8;
  }
  .comm_tool_list{
    margin:0 auto;
    width:80vw;
  }
  .comm_tool_list li{
    margin-right:4vw;
  }
  .comm_tool_img{
    width:22vw;
  }
  .comm_tool_img_txt{
    margin-top:1.3rem;
    font-size:1.2rem;
  }
}

/*sakusaku_chart*/
.sakusaku_chart{
  width:100%;
  background-color:#f5f6f8;
}
.sakusaku_chart .innr{
  position:relative;
  margin:0 auto;
  padding:5rem 0;
  width:100%;
  max-width:800px;
  text-align:center;
}
.sakusaku_chart_ttl{
  margin-bottom:1.5rem;
  font-size:2.4rem;
  line-height:1.8;
  font-weight:400;
}
.sakusaku_chart_txt{
  margin-bottom:2.8rem;
  font-size:1.3rem;
  line-height:1.8;
}
.sakusaku_chart_img{
  width:100%;
  height:auto;
}
@media screen and (max-width:896px){
  .sakusaku_chart .innr{
    margin:0 auto;
    padding:8vw 0;
    width:90vw;
  }
  .sakusaku_chart_ttl{
    margin-bottom:4vw;
    font-size:1.7rem;
    line-height:1.8;
  }
  .sakusaku_chart_txt{
    margin-bottom:6vw;
    font-size:1.3rem;
    line-height:1.8;
  }
}

/*task_chart*/
.task_chart{
  width:100%;
  background-color:#fff;
}
.task_chart .innr{
  position:relative;
  margin:0 auto;
  padding:5rem 0;
  width:100%;
  max-width:800px;
  text-align:center;
}
.task_chart_list{
  margin:0 auto;
  display:flex;
  justify-content:space-between;
  width:100%;
  max-width:720px;
}
.task_chart_list li{
  margin-right:4rem;
  text-align:left;
}
.task_chart_list li:last-child{
  margin-right:0;
}
.task_chart_ttl{
  margin-bottom:1.5rem;
  font-size:2.4rem;
  line-height:1.4;
  font-weight:400;
}
.task_chart_txt{
  margin-bottom:2rem;
  font-size:1.3rem;
  line-height:1.8;
}
.task_chart_img1{
  width:34rem;
  height:auto;
}
.task_chart_img2{
  width:32.4rem;
  height:auto;
}
@media screen and (max-width:896px){
  .task_chart .innr{
    margin:0 auto;
    padding:8vw 0;
    width:90vw;
  }
  .task_chart_list{
    margin:0 auto;
    display:flex;
    flex-direction:column-reverse;
    width:90vw;
  }
  .task_chart_list li{
    margin-right:0;
    margin-bottom:6vw;
  }
  .task_chart_list li:last-child{
    margin-right:0;
  }
  .task_chart_list li:first-child{
    margin-right:0;
    margin-bottom:0;
  }
  .task_chart_ttl{
    margin-bottom:4vw;
    font-size:1.7rem;
    line-height:1.7;
    text-align:center;
  }
  .task_chart_txt{
    margin-bottom:4vw;
    font-size:1.3rem;
    line-height:1.7;
  }
  .task_chart_img1{
    width:90vw;
    height:auto;
  }
  .task_chart_img2{
    margin:0 auto;
    width:80vw;
    height:auto;
  }
}

/*about_ganttchat*/
.about_ganttchat{
  width:100%;
  background-color:#fff;/*20211129*/
}
.about_ganttchat .innr{
  position:relative;
  margin:0 auto;
  padding:5rem 0;
  width:100%;
  max-width:800px;
  text-align:center;
}

/*20210526*/
.about_ganttchat_ttl{
  margin-bottom:3rem;
  font-size:2.4rem;
  line-height:1.8;
  font-weight:400;
}
.about_ganttchat_txt{
  margin-bottom:2.8rem;
  font-size:1.3rem;
  line-height:1.8;
}
.about_ganttchat_list{
  margin:0 auto;
  margin-bottom:5rem;
  display:flex;
  justify-content:space-between;
  width:100%;
  max-width:735px;
}
.about_ganttchat_list.mgn_btm{
  margin-bottom:4rem;
}
/*20210526_END*/

.about_ganttchat_list li{
  margin-right:3rem;
  width:33%;
}
.about_ganttchat_list li:last-child{
  margin-right:0;
}
.person_comm{
  position:relative;
  display: flex;
  flex-direction: column;
  height:100%;
  width:100%;
  text-align:left;
}
.person_comm_txt{
  margin-bottom:0;
  text-align:left;
  font-size:1.1rem;
  line-height:1.8;
  font-weight:700;
}
.person_icon{
  margin-top:auto;
  position:relative;
  width:100%;
}
.person_icon::before{
  content:'';
  position:absolute;
  width:14.6rem;
  height:1.3rem;
  top:1.4rem;
  left:0;
  background-image:url("../images/landing/person_comm.svg");
  background-repeat:no-repeat;
  background-position:top 0 left 0;
  background-size:100%;
}
.person_icon_img{
  margin-left:auto;
  width:7rem;
  height:auto;
}
.person_icon_txt{
  position:absolute;
  bottom:0;
  left:0;
  font-size:1.4rem;
  line-height:1.4;
  font-weight:700;
  text-align:left;
}
.person_icon_txt span{
  display:block;
  font-size:1.2rem;
  font-weight:400;
}
@media screen and (max-width:896px){
  .about_ganttchat .innr{
    margin:0 auto;
    padding:8vw 0;
    width:90vw;
  }
  .about_ganttchat_ttl{
    margin-bottom:4vw;
    font-size:1.7rem;
    line-height:1.7;
  }
  .about_ganttchat_txt{
    margin-bottom:6vw;
    font-size:1.3rem;
    line-height:1.7;
  }
  .about_ganttchat_list{
    margin:0 auto;
    margin-bottom:10vw;
    display:block;
    width:90vw;
  }
  
  /*20210526*/
  .about_ganttchat_list.mgn_btm{
    margin-bottom:4vw;
  }
  /*20210526*/
  
  .about_ganttchat_list li{
    margin-right:0;
    margin-bottom:4vw;
    width:100%;
  }
  .about_ganttchat_list li:last-child{
    margin-right:0;
    margin-bottom:0;
  }
  .person_comm{
    margin:0 auto;
    width:80vw;
  }
  .person_comm_txt{
    margin-bottom:1vw;
    font-size:1.1rem;
    line-height:1.8;
  }
  .person_icon{
    margin:0 auto;
    width:80vw;
  }
  .person_icon::before{
    width:57vw;
    height:4vw;
    background-image:url("../images/landing/person_comm_sp.svg");
    background-size:100%;
  }
  .person_icon_img{
    width:20vw;
  }
  .person_icon_txt{
    position:absolute;
    bottom:0;
    left:0;
    font-size:1.4rem;
    line-height:1.4;
    font-weight:700;
    text-align:left;
  }
  .person_icon_txt span{
    display:block;
    font-size:1.2rem;
    font-weight:400;
  }
}
