@charset "UTF-8";

.header .header_logo.policy_logo{
  margin:0 auto!important;
}
.contents_innr{
  margin:0 auto;
  padding-top:60px;
  width:100%;
  max-width:840px;
}
@media screen and (max-width:896px){
  .contents_innr{
    padding-top:10vw;
  }
}

/*personal_info_btn*/
.personal_info_btn{
  margin:60px auto 0 auto;
  padding:0;
  width:280px;
  text-align:center;
}
.personal_info_btn a{
  display:block;
  margin:0;
  padding:10px;
  font-size:13px;
  font-weight:bold;
  color:#353535!important;
  text-decoration:none!important;
  border-radius:5px;
  background-color:#ededed;
  box-sizing:border-box;
  border:1px solid #838383;
}
@media screen and (max-width:896px){
  .personal_info_btn{
    margin:40px auto 0 auto;
  }
}

/*table*/
table.type01 {
  margin:20px auto 30px auto;
  padding: 0;
  width:100%;
  height:auto;
  border-collapse: collapse;
  text-align: left;
  font-weight:normal;
  font-size:12px;
  line-height:22px;
  letter-spacing:0;
  font-feature-settings:"palt";
  vertical-align: top;
  border-left:1px solid #e3e3e3;/*rgba(53,53,53,0.5);*/
}
table.type01 thead th{
  margin:0;
  padding:10px;
  vertical-align: top;
  border-top: 1px solid #e3e3e3;
  border-right: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
}
table.type01 thead td {
  margin:0;
  padding:10px;
  vertical-align: top;
  border-top: 1px solid #e3e3e3;
  border-right: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
}
table.type01 thead th.cols1{
  font-weight:normal;
  width:20%;
}
table.type01 thead th.cols2{
  font-weight:normal;
  width:30%;
}
table.type01 thead th.cols3{
  font-weight:normal;
  width:50%;
}

/*privacy_tab*/
.privacy_tab {
  position:relative;
  text-align:left;
  list-style:none;
  margin:0 0 50px 0;
  padding:0;
  font-size:12px;
  font-weight:600;
  line-height:24px;
}
.privacy_tab:before {
  z-index:1;
}
.privacy_tab:after {
  position: absolute;
  content: "";
  width:100%;
  bottom:0;
  left:0;
  border-bottom: 1px solid #e3e3e3;
  z-index: 1;
}
.privacy_tab li{
  position: relative;
  margin:0 2px;
  padding:2px 15px;
  color:#a5a5a5;
  border:1px solid #e3e3e3;
  background: #e3e3e3;
  display: inline-block;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  z-index: 0;
}
.privacy_tab li:first-child{
  margin-left:30px;
}
.privacy_tab a{
  color:#a5a5a5;
}
.privacy_tab li.selected {
  background: #fff;
  color:#4285f4;
  z-index:2;
  border-bottom-color: #fff;
}
@media screen and (max-width:896px){
  .privacy_tab {
    margin:0 0 30px 0;
  }
}

/*privacy_main*/
.privacy_main{
  margin:0 auto;
  padding-bottom:8rem;
  font-size:12px;
  font-weight:500;
  text-align:left;
  width:100%;
}
.privacy_main h2.main_ttl{
  margin:20px 0;
  padding:0;
  font-size:13px;
  font-weight:600;
}
.privacy_main h2.main_ttl2{
  margin:0 0 20px 0;
  padding:0;
  font-size:13px;
  font-weight:600;
  text-align:center;
}
.privacy_main h2.list_ttl{
  margin:20px 0 5px 0;
  padding:0;
  font-size:13px;
  font-weight:600;
}
.privacy_main p.main_txt{
  margin:0 0 50px 0;
  padding:0;
  font-size:12px;
  font-weight:500;
}
.privacy_main p.main_txt2{
  margin:0 0 20px 0;
  padding:0;
  font-size:12px;
  font-weight:500;
}
.privacy_main p.main_txt3{
  margin:0;
  padding:0;
  font-size:12px;
  font-weight:500;
  text-align:right;
}
.privacy_main p.cnts_txt{
  margin:0;
  padding:0;
  font-size:12px;
  font-weight:500;
}
.privacy_main span{
  font-weight:600;
}
.privacy_main a{
  color:#2286fb;
  text-decoration:underline;
}
/*privacy_list*/
.privacy_list{
  margin:0;
  padding:0;
}
.privacy_list li{
  margin:0 0 5px 0;
  padding:0;
  padding-left:1.5em;
  text-indent:-1.5em;
  font-size:12px;
  font-weight:500;
}
@media screen and (max-width:896px){
  .privacy_main{
    padding-bottom:15vw;
    width:90%;
  }
  .privacy_main p.main_txt{
    margin:0 0 30px 0;
  }
}
