@charset "UTF-8";

/*contact*/
.contact_fotm{
  position:relative;
  margin:0 auto;
  padding:2rem;
  width:100%;
  max-width:54rem;
  border-radius:.4rem;
  background-color:#f5f6f8;
}
.contact_fotm_col{
  position:relative;
  margin-bottom:1rem;
  display:flex;
  justify-content:space-between;
  text-align:left;
}
.contact_fotm_name{
  margin-right:2rem;
  padding-top:1rem;
  width:13.5rem;
  text-align:right;
}
.contact_fotm_input{
  width:100%;
  text-align:left;
}
.contact_fotm_input > input:not([type=submit]) {
  display: block;
  font-size:1.3rem;
  line-height:1;
  color:#404142;
  padding:0.5rem 1.0rem;
  height: calc( 4rem + 2px);
  width:100%;
  outline:0;
  background-color: #fff;
  border: 1px solid #eaeaea;
  border-radius: .33rem;
}
.contact_fotm_comment {
  resize: none;
  display: block;
  padding: 0.8rem 1rem;
  width: 100%;
  height: 110px;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #232323;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e3e3e3;
  border-radius: 0.5rem;
}
.contact_form_submit{
  display: block;
  margin:0 auto;
  margin-top:2rem;
  margin-bottom:1rem;
  padding: 1rem 1.7rem;
  width: 14rem;
  font-size: 1.3rem;
  line-height: 1;
  color: #fff;
  text-decoration: none;
  border-radius: 0.33rem;
  background-color: #2286fb;
  transition: opacity .5s ease-out;
}
.contact_form_submit:hover{
  opacity:0.7;
}
@media screen and (max-width:896px){
  .contact_fotm{
    margin-bottom:4vw;
    padding:4vw;
    width:90vw;
  }
  .contact_fotm_col{
    position:relative;
    margin-bottom:1rem;
    display:block;
    text-align:left;
  }
  .contact_fotm_name{
    margin-right:0;
    padding-top:0;
    width:auto;
    text-align:left;
  }
  .contact_form_submit{
    margin-top:6vw;
    margin-bottom:2vw;
  }
}

/*company_main*/
.company_main{
  width:100%;
  background-color:#fff;
}
.company_main .innr{
  position:relative;
  margin:0 auto;
  padding:5rem 0;
  width:100%;
  max-width:800px;
  text-align:center;
}
.company_main_ttl{
  margin-bottom:3rem;
  font-size:2.6rem;
  line-height:1.3;
  font-weight:400;
}
.company_main_ttl span{
  display:block;
  font-size:1.4rem;
  color:#2286fb;
}
.company_main_txt{
  margin-bottom:4rem;
  font-size:1.3rem;
  line-height:1.8;
}
.company_main_list{
  display:flex;
  justify-content:center;
  width:100%;
}
.company_main_list li{
  margin-right:4rem;
  text-align:center;
}
.company_main_list li:last-child{
  margin-right:0;
}
.company_list_img{
  margin:0 auto;
  width:7.8rem;
  height:auto;
}
.company_list_img img{
  width:100%;
  height:auto;
  border-radius:50%;
  overflow:hidden;
}
.company_list_img_txt{
  margin-top:1.3rem;
  font-size:1.5rem;
  line-height:1.6;
  font-weight:700;
}
.company_list_img_txt span{
  display:block;
  font-size:1.2rem;
  font-weight:400;
}
.list_more_btn{
  position:relative;
  display:block;
  margin:0 auto;
  width:14rem;
  height:30px;
  text-align:center;
  font-size:1.3rem;
  line-height:29px;
  color:#2286fb;
  text-decoration:none;
  border-radius:30px;
  border: 1px solid #2286fb;
  box-sizing: border-box;
  background-color:#fff;
  opacity:1;
}
.list_more_btn::after{
  content:'';
  position:absolute;
  width:0.5rem;
  height:0.5rem;
  top:50%;
  right:1rem;
  margin-top:-0.25rem;
  border-top:0.15rem solid #2286fb;
  border-right:0.15rem solid #2286fb;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.list_more_btn:hover{
  color:#fff;
  background-color:#2286fb;
  opacity:1;
}
.list_more_btn:hover::after{
  border-color:#fff;
}
@media screen and (max-width:896px){
  .company_main .innr{
    margin:0 auto;
    padding:8vw 0;
    width:90vw;
  }
  .company_main_ttl{
    margin-bottom:4vw;
    font-size:1.7rem;
    line-height:1.7;
  }
  .company_main_ttl span{
    font-size:1.4rem;
  }
  .company_main_txt{
    margin-bottom:8vw;
    font-size:1.3rem;
    line-height:1.7;
  }
  .company_main_list{
    margin:0 auto;
    width:90vw;
  }
  .company_main_list li{
    margin-right:2vw;
  }
  .company_main_list li:last-child{
    margin-right:0;
  }
  .company_list_img{
    width:23vw;
    height:auto;
  }
  .company_list_img_txt{
    margin-top:1rem;
    font-size:1.2rem;
    line-height:1.6;
  }
  .company_list_img_txt span{
    font-size:1rem;
  }
  .list_more_btn{
    width:30vw;
    height:30px;
    font-size:1rem;
    line-height:29px;
    border-radius:30px;
  }
}


/*company_detail*/
.company_detail{
  width:100%;
  background-color:#f5f6f8;
}
.company_detail .innr{
  position:relative;
  margin:0 auto;
  padding:5rem 0;
  width:100%;
  max-width:800px;
  text-align:center;
}
.company_detail_list{
  margin:0 auto;
  list-style:none;
  text-align:left;
  width:38.5rem;
  border-top:1px solid #d3d3d3;
}
.company_detail_list li{
  padding-top:2.2rem;
  padding-bottom:2.2rem;
  display:flex;
  justify-content:space-between;
  align-items:center;
  text-align:left;
  width:100%;
  font-size:1.2rem;
  line-height:1.6;
  border-bottom:1px solid #d3d3d3;
  box-sizing:border-box;
}
.company_list_left{
  width:32%;
  text-align:left;
}
.company_list_right{
  width:68%;
  text-align:left;
}
@media screen and (max-width:896px){  
  .company_detail .innr{
    margin:0 auto;
    padding:8vw 0;
    width:90vw;
  }
  .company_detail_list{
    width:90vw;
  }
  .company_detail_list li{
    padding-top:2rem;
    padding-bottom:2rem;
  }
  .company_list_left{
    width:30%;
  }
  .company_list_right{
    width:70%;
  }
}

