@charset "UTF-8";

body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,form,fieldset,input,textarea,p,blockquote,th,td,figure{margin:0;padding:0;}
h1,h2,h3,h4,h5,h6{font-size:100%;font-weight:normal;}
input,button,textarea,select{margin:0;padding:0;background:none;border:none;border-radius:0;outline:none;-webkit-appearance:none;-moz-appearance:none;appearance:none}
html,body{margin:0;padding:0;width:100%;}
section,footer,header{margin:0;padding:0}
ul{list-style:none}ul li{vertical-align:top}
label {display: inline-block;margin-bottom: 0.5rem;}
button, input {overflow: visible;}
button, select {text-transform: none;}
input, button, select, optgroup, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
textarea {
  overflow: auto;
  resize: vertical;
}
input[type="radio"], input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}
input:-internal-autofill-selected{
  background-color:transparent;
}
button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {cursor: pointer;}
button, [type="button"], [type="reset"], [type="submit"] {-webkit-appearance: button;}
*, *::before, *::after {box-sizing: border-box;}
em {font-style: normal;}
p {margin-top: 0;margin-bottom: 1rem;}
a {outline: none;}a img {outline:none;}a:focus, *:focus {outline:none;}
a:link{text-decoration:none;}a:visited{text-decoration:none;}a:active{text-decoration:none;}
a:hover{
  text-decoration:none;
  opacity:0.7;
  transition:.4s;
  filter: alpha(opacity=70);
  -ms-filter: "alpha(opacity=70)";
}
html, body {
  overflow-x:hidden;
}
html{
  font-size:62.5%;
}
body{
  font-family: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', Meiryo, メイリオ, Osaka, 'MS PGothic', arial, helvetica, sans-serif;
	font-size:1.2rem;
  font-weight:400;
	-webkit-text-size-adjust:100%;
  text-align: left;
	color:#404142;
  width:100%;
  background-color:#fff;
}
img {
	width:100%;
	height:auto;
	vertical-align:bottom;
}
img[src$=".svg"]{
  width:100%;
  height:auto;
}
.invisible {
  visibility: hidden !important;
}

.is_pc{ display:block; }
.is_sp{ display:none;}
@media screen and (max-width:896px){
  .is_pc{ display:none; }
  .is_sp{ display:block; }
}

#wrapper{
  position:relative;
  margin:0;
  padding:0;
  width:100%;
  overflow-x:hidden;
}

/*--------------------------------
header
----------------------------------*/
.header {
  position:fixed;
  width:100%;
  height:60px;
  top:0;
  left:0;
  border-bottom: 1px solid #efefef;
  background-color:#fff;
  z-index:100;
}
.header .innr{
  padding:0 30px;
  height:60px;
  display:flex;
  align-items:center;
}
.header .header_logo{
  margin:0;
  width:161px;
  height:auto;
  line-height:inherit;
}
.header .header_logo_btn{
  text-decoration:none;
  opacity:1;
}
.header .header_logo_img{
  width:161px;
}
.header_menu{
  margin-left:auto;
  margin-right:2.5rem;
  display:flex;
  height:100%;
}
.header_menu li{
  margin-right:1.5rem;
  height:100%;
}
.header_menu li:last-child{
  margin-right:0;
}
.header_menu li a{
  display:block;
  padding:0 2rem;
  height:100%;
  font-size:1.2rem;
  line-height:60px;
  font-weight:700;
  color:#595757;
  transition:none;
  opacity:1;
}
.header_menu li a.menu_active{
  border-bottom:4px solid #2286fb;
  box-sizing:border-box;
  opacity:1;
}
.header_menu li a:hover{
  border-bottom:4px solid #2286fb;
  box-sizing:border-box;
  opacity:1;
}
.header_contact{
  display: block;
  padding:0 2rem;
  height:30px;
  vertical-align: middle;
  text-decoration: none;
  font-size: 1.2rem;
  line-height:28px;
  font-weight:700;
  color:#2286fb;
  border-radius:.4rem;
  background-color:#fff;
  border:1px solid #2286fb;
  box-sizing:border-box;
  opacity:1;
}
.header_contact:hover{
  opacity:1;
  color:#fff;
  background-color:#2286fb;
}
@media screen and (max-width:896px){
  .header {
    height:13vw;
  }
  .header .innr{
    padding:0 3vw;
    height:13vw;
  }
  .header .header_logo{
    width:42vw;
  }
  .header .header_logo_btn{
    text-decoration:none;
    opacity:1;
  }
  .header .header_logo_img{
    width:42vw;
  }
  .header_menu{
    position:absolute;
    margin-left:0;
    margin-right:0;
    display:block;
    width:100%;
    height:0;/*auto;*/
    top:13vw;
    left:0;
    overflow:hidden;
    background-color:#fff;
    transition: height .3s ease-out;
    z-index:10;
  }
  .header_menu.menuOpen{
    height:36vw;
  }
  .header_menu li{
    margin-right:0;
    width:100%;
    height:12vw;
    border-bottom:1px solid #efefef;
    background-color:#fff;
  }
  .header_menu li:last-child{
    margin-right:0;
  }
  .header_menu li a{
    padding:0 2rem;
    height:12vw;
    font-size:1.2rem;
    font-weight:700;
    line-height:12vw;
  }
  .header_menu li a.menu_active{
    border:none;
  }
  .header_menu li a:hover{
    border:none;
  }
  .header_contact{
    margin-left: auto;
    margin-right:11vw;
    padding:0 3vw;
    height:7vw;
    font-size: 1.2rem;
    line-height:6.5vw;
    border-radius:.4rem;
  }
  .header_contact:hover{
    color:#2286fb;
    background-color:#fff;
  }
}

/*menu_btn*/
.header .menu_btn{
	position:absolute;
	width:8vw;
	height:8vw;
	top:50%;
	right:2vw;
  margin-top:-4vw;
	cursor:pointer;
	overflow:hidden;
	z-index:15;
  display:none;
}
.header .menu_btn a:link{
	position:relative;
	display:block;
	width:8vw;
	height:8vw;
	opacity:1;
	top:0;
	left:0;
}
.header .menu_btn a:hover{
	opacity:1;
}
/*menu-trigger*/
.header .menu-trigger,.header .menu-trigger span {
	display: inline-block;
	transition: all .4s;
	box-sizing: border-box;
}
.header .menu-trigger {
	position:relative;
	top:2vw;
  left:1vw;
	width:6vw;
	height:4vw;
}
.header .menu-trigger.hover{
	opacity:1;
}
.header .menu-trigger span {
	position:absolute;
	left:0;
	width:6vw;
	height:2px;
	background-color:#595757;
	border-radius:.2rem;
}
.header .menu-trigger span:nth-of-type(1) {
	top:0;
}
.header .menu-trigger span:nth-of-type(2) {
	top:1.6vw;
}
.header .menu-trigger span:nth-of-type(3) {
	bottom:0;
}
.header .menu-trigger.active span:nth-of-type(1) {
	-webkit-transform: translateY(1.8vw) rotate(-45deg);
	transform: translateY(1.8vw) rotate(-45deg);
}
.header .menu-trigger.active span:nth-of-type(2) {
	opacity:0;
}
.header .menu-trigger.active span:nth-of-type(3) {
	-webkit-transform: translateY(-1.8vw) rotate(45deg);
	transform: translateY(-1.8vw) rotate(45deg);
}
@media screen and (max-width:896px){
  .header .menu_btn{
    display:block;
  }
}

/*main_conts*/
#main_conts{
  position:relative;
  margin-top:60px;
  width:100%;
  height:auto;
}
@media screen and (max-width:896px){
  #main_conts{
    margin-top:13vw;
  }
}

/*pagetop*/
#pagetop{
  position:fixed;
  width:50px;
  height:50px;
  bottom:15px;
  right:15px;
  overflow: hidden;
  background-color: #424242;
  transition: all 0.5s linear;
  cursor:pointer;
  z-index:100;
}
#pagetop a{
  display:block;
}
#pagetop:before {
  content:'';
  position:absolute;
  width:20px;
  height:12px;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  transition: all 0.25s linear;
  background-image:url("../images/landing/pagetop_arw.svg");
  background-repeat:no-repeat;
  background-position:center;
  background-size:100%;
}
#pagetop:hover::before {
  top:20px;
}
@media screen and (max-width:896px){
  #pagetop{
    display:none;
    width:10vw;
    height:10vw;
    max-width:50px;
    max-height:50px;
    bottom:0;
    right:0;
  }
}

/*lp_footer*/
#lp_footer{
  text-align:center;
  color:#fff;
  width:100%;
  background-color:#424242;
}
.lp_footer_logo{
  margin:0 auto;
  padding-top:4rem;
  padding-bottom:3rem;
  width:17.7rem;
  height:auto;
}
.lp_footer_logo a,.lp_footer_logo a:hover{
  opacity:1;
}
.lp_footer_list{
  padding-bottom:2rem;
  display:flex;
  justify-content:center;
  align-items:center;
}
.lp_footer_list li{
  margin-right:2rem;
}
.lp_footer_list li:last-child{
  margin-right:0;
}
.lp_footer_list li a{
  font-size:1.1rem;
  color:#fff;
  text-decoration:none;
}
.lp_footer_copyright{
  margin-bottom:0;
  padding-bottom:1.2rem;
  font-size:1rem;
  line-height:1.4;
}
@media screen and (max-width:896px){
  .lp_footer_copyright{
    font-size:1rem;
  }
}
